<template>
  <div>
    <v-select
      v-if="hasCode"
      v-bind="$attrs"
      :value="value"
      :options="options"
      :fetch-data="fetchData"
      :filterable="false"
      :reduce="option=>option.code"
      :get-option-label="(a) => getLabel(a)"
      :searchable="searchable"
      :clearable="clearable"
      :placeholder="placeholder"
      :disabled="disabled"
      :multiple="multiple"
      :label="label"
      @open="onOpen"
      @close="onClose"
      @search="handleSearch"
      @input="$emit('input', $event)"
    >
      <template #option="option">
        <slot name="option" v-bind="option" />
      </template>

      <template #selected-option="option">
        <slot name="selected-option" v-bind="option" />
      </template>
      <template #list-footer>
        <li
          v-show="hasNextPage"
          ref="load"
          class="loader ml-2 mb-50 text-center text-muted"
        >
          {{ $t("input.ajax-select.loading-message") }}
        </li>
      </template>
      <div slot="no-options">
        <template v-if="isLoadingInitialData">
          {{ $t("input.ajax-select.initial-loading-message") }}
        </template>
        <template v-else>
          {{ $t("input.ajax-select.no-options-message") }}
        </template>
      </div>
    </v-select>
    <v-select
      v-else
      v-bind="$attrs"
      :v-model="value"
      :options="options"
      :fetch-data="fetchData"
      :disabled="disabled"
      :searchable="searchable"
      :placeholder="placeholder"
      :multiple="multiple"
      :get-option-label="(a) => getLabel(a)"
      :label="label"
      @open="onOpen"
      @close="onClose"
      @search="handleSearch"
      @input="$emit('input', $event)"
    >
      <template #option="option">
        <slot name="option" v-bind="option" />
      </template>

      <template #selected-option="option">
        <slot name="selected-option" v-bind="option" />
      </template>
      <template #list-footer>
        <li
          v-show="hasNextPage"
          ref="load"
          class="loader ml-2 mb-50 text-center text-muted"
        >
          {{ $t("input.ajax-select.loading-message") }}
        </li>
      </template>
      <div slot="no-options">
        <template v-if="isLoadingInitialData">
          {{ $t("input.ajax-select.initial-loading-message") }}
        </template>
        <template v-else>
          {{ $t("input.ajax-select.no-options-message") }}
        </template>
      </div>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'AjaxSelect',
  components: {
    vSelect,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array, String, Number, Object],
      default: null,
    },
    /**
     * (page: number, searchString?: string) => { data: Array, meta?: { current_page: number, last_page: number } }
     */
    fetchData: {
      type: Function,
      required: true,
    },
    /**
     * Enable/disable filtering the options.
     */
    searchable: {
      type: Boolean,
      default: true,
    },
    filter: {
      type: Function,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    hasCode: {
      type: Boolean,
      default: false,
    },
    code: {
      type: Function,
      required: false,
    },
    label: {
      type: String,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    cleanOptions: {
      type: Boolean,
      default: false,
    },
    callingAgain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observer: new IntersectionObserver(this.infiniteScroll),
      optionsFromSearch: [],
      search: '',
      isLoadingInitialData: true,

      options: [],
      nextPage: 1,
      actualPage: null,
      hasNextPage: false,

      timeoutID: null,
      searchString: '',
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  async mounted() {
    await this.manageOptions();
  },
  methods: {
    async manageOptions() {
      if (this.options.length > 0) {
        this.options = [];
        this.nextPage = 1;
        await this.getInitialData();
      }
    },
    async fetchNextPage() {
      let force = false;
      if (this.nextPage === 1) {
        force = true;
      }
      if (this.actualPage === this.nextPage) {
        return;
      }
      if ((this.searchString == null || this.searchString === '') && this.nextPage !== 1) {
        this.hasNextPage = true;
      }
      if (this.cleanOptions) {
        this.options = [];
        this.cleanOptions = false;
        this.$emit('ChangeCleanOptions');
      }
      const response = await this.fetchData(
        this.nextPage,
        this.searchString,
        force = true,
      );
      console.log(this.filter)
      const filteredResponse = this.filter
        ? response?.data?.filter(this.filter)
        : response?.data;
      if (this.hasCode) {
        const newOptions = this.code(filteredResponse);
        this.options.push(...newOptions);
        this.options = new Set(this.options);
        this.options = Array.from(this.options);
      } else {
        this.options.push(...filteredResponse);
      }
      this.actualPage = this.nextPage;
      this.nextPage = response.meta ? response.meta.current_page + 1 : null;
      this.hasNextPage = response.meta && response.meta.last_page > response.meta.current_page;
    },
    async getInitialData() {
      this.isLoadingInitialData = true;
      await this.fetchNextPage();
      this.isLoadingInitialData = false;
    },
    async onOpen() {
      if (this.callingAgain) {
        await this.manageOptions();
      }
      if (this.nextPage === 1 || this.options.length === 0 || this.cleanOptions) {
        await this.getInitialData();
      }
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.actualPage = null;
      this.observer.disconnect();
      this.$emit('on-close');
    },
    async handleSearch(search, loading) {
      loading(true);
      this.hasNextPage = false;
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }
      this.timeoutID = setTimeout(async () => {
        const searched = this.options.filter((element) => translateTranslationTable(this.locale, element.name).toLowerCase().indexOf(search.toLowerCase()) > -1);
        if (searched.length > 0 && search !== '') {
          this.options = searched;
          this.timeoutID = null;
          loading(false);
          return;
        }
        this.options = [];
        this.nextPage = 1;
        this.actualPage = null;
        this.searchString = search;
        this.fetchNextPage();
        this.timeoutID = null;
        loading(false);
      }, 500);
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const { scrollTop } = target.offsetParent;
        if (this.hasNextPage) {
          this.fetchNextPage();
          await this.$nextTick();
          ul.scrollTop = scrollTop;
        }
      }
    },
    translateTranslationTable,
    getLabel(a) {
      return this.label === 'name' ? translateTranslationTable(this.locale, a.name) : a;
    },
  },
};
</script>

<style scoped></style>
